import Vue from 'vue';
import Router from 'vue-router';
var HomeComponent = function () { return import(/* webpackChunkName: 'HomeComponent' */ './pages/home/index.vue'); };
var routes = [
    {
        path: '/',
        component: HomeComponent,
        name: 'HomeComponent',
    }
];
Vue.use(Router);
export default new Router({
    routes: routes,
});
