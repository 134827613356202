import Vue from 'vue';
import App from './app.vue';
import router from './router';
var vub = require('viewport-units-buggyfill');
export default function vwAdjust() {
    window.addEventListener('load', function () {
        vub.init({ hacks: window.viewportUnitsBuggyfillHacks });
    });
}
Vue.config.productionTip = false;
new Vue({
    el: '#app',
    render: function (h) { return h(App); },
    router: router,
});
