<template>
  <router-view/>
</template>

<script type="text/ecmascript-6">
export default {
  data () {
    return {
      data: ''
    }
  }
}
</script>

<style lang="stylus">
  html, body, div, * 
    margin 0
    padding 0
    box-sizing border-box
</style>
